import React, { useState, useEffect } from 'react'
import './App.css'
import { FaCheck, FaCopy, FaSpinner, FaRedo } from 'react-icons/fa'
import { toast } from 'react-toastify'

const { REACT_APP_API_URL } = process.env

function App() {
  const [loading, setLoading] = useState(false)
  const [copied, setCopied] = useState(false)
  const [pw, setPw] = useState('')

  const fetchPw = () => {
    setLoading(true)
    setCopied(false)
    fetch(`${REACT_APP_API_URL || ''}/api/pw`).then(async res => {
      const json = await res.text()
      const { pw } = JSON.parse(json)
      setPw(pw)
    }).catch(ex => {

    }).finally(() => setLoading(false))
  }

  useEffect(() => {
    fetchPw()
  }, [])

  return (
    <div className="App">
      <div className="password">{loading ? '------' : pw}</div>
      <div>Length: {pw.length}</div>
      <div className="buttons">
        <button type="button"
          disabled={loading || copied}
          className={`btn btn-${copied ? 'success' : 'primary'}`}
          onClick={async () => {
            try {
              await navigator.clipboard.writeText(pw)
              setCopied(true)
            } catch (err: any) {
              toast.error(`error: ${err.message}`, { autoClose: false })
            }
          }}
        >{copied ? <FaCheck /> : <><FaCopy />Copy</>}</button>
        <button type="button"
          disabled={loading}
          className="btn btn-primary"
          onClick={() => fetchPw()}
        >{loading ? <FaSpinner className="spin" /> : <><FaRedo />Regenerate</>}</button>
      </div>
    </div>
  );
}

export default App;
